@import url("https://fonts.googleapis.com/css?family=Work+Sans:200,300,400,500,600,700,800,900");
@import url("https://fonts.googleapis.com/css?family=Righteous&display=swap");
/* 
font-family: 'Work Sans', sans-serif;
font-family: 'Righteous', cursive;
*/

/*========================
 (1). Reset css 
 (2). Common Styles Css
 (3). Header Css
 (4). Top Sider Css
 (5). About us Css
 (6). Skills Css
 (7). My Resume Css
 (8). Portfolio css
 (9). Animation Css
========================*/

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

.portfolio {
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  background: #1e2326;
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  color: #fff;
  overflow-x: hidden;

.container {
  display: flex;
  flex-direction: column;
  margin-left: 50%;
  transform: translateX(-50%);
}
::-webkit-scrollbar {
  width: 0;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
  font-family: "Work Sans", sans-serif;
}
.clearfix {
  display: inline-block;
}
/* start commented backslash hack \*/
* html .clearfix {
  height: 1%;
}
.clearfix {
  display: block;
}
/* close commented backslash hack */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
a {
  text-decoration: none;
  transition: all 0.5s ease 0s;
  color: #37bbee;
  cursor: pointer;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  max-width: 100%;
  display: block;
}
/*========================================
	 Common Styles START
========================================*/
body {
  margin: 0;
  padding: 0;
  background: #1e2326;
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  color: #fff;
}
a:focus {
  box-shadow: none;
}
a:focus,
a:hover {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  box-shadow: none;
}
.cd-top {
  bottom: 0px;
  color: #fff;
  display: block;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  opacity: 1;
  position: absolute;
  right: 0;
  text-align: center;
  transition: all 0.8s ease 0s;
  font-family: "Work Sans", sans-serif;
  width: 50px;
  z-index: 9999;
  letter-spacing: 0px;
  font-weight: 600;
  background-color: #1cb698;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  left: 0;
  margin: 0 auto;
  top: -25px;
  border-radius: 100%;
  padding: 0;
}
.cd-top.cd-is-visible {
  opacity: 1;
}
.cd-top .fa {
  color: #fff;
}
.sticky-wrapper.is-sticky .header_wapper {
  z-index: 99999 !important;
}
.db_section_top {
  padding-top: 60px;
  margin-top: -60px;
}
.db_section_top_gap {
  padding: 65px 0px;
}
.section_bg {
  background: #252a2e;
}
/* Button Css  */
.btn01 {
  font-size: 16px;
  color: #fff;
  letter-spacing: 0.5px;
  font-weight: 500;
  background-color: transparent;
  display: inline-block;
  border-radius: 0px;
  vertical-align: middle;
  padding: 10px 22px;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  border: 1px solid #fff;
  -box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.btn01:hover {
  color: #fff;
}
.btn01:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1cb698;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  border-radius: 0px;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.btn01:hover,
.btn01:focus,
.btn01:active {
  color: #fff;
  border-color: #1cb698;
}
.btn01:hover:before,
.btn01:focus:before,
.btn01:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
/*========================================
	 Common Styles END
========================================*/

/*========================================
	Header Css START
========================================*/
header {
  width: 100%;
  position: fixed;

  z-index: 1000;
}
.header_wapper {
  position: relative;
  transition: all 0.5s ease 0s;
  background: #1e2326;
  padding: 15px 0px;
  z-index: 1000;
}
.sticky-wrapper.is-sticky .header_wapper {
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 8px 0px;
}
.logo a {
  font-size: 36px;
  color: #1cb698;
  transition: all 0.5s ease 0s;
  font-family: "Righteous", cursive;
}
.portfolio
#cssmenu > ul > li > a {
  position: relative;
  padding: 10px 0px;
  margin: 0 15px;
  text-transform: uppercase;
}
.portfolio
#cssmenu > ul > li > a:before {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  padding: 5px 0px;
  max-width: 0;
  color: #1cb698;
  content: attr(data-hover);
  -webkit-transition: max-width 0.8s;
  -moz-transition: max-width 0.8s;
  transition: max-width 0.8s;
}
.portfolio
#cssmenu > ul > li > a:hover:before,
#cssmenu > ul > li > a:focus::before {
  max-width: 100%;
}
.portfolio
#cssmenu > ul > li:last-child > a {
  margin-right: 0px;
}


/*========================================
	Header Css END 
========================================*/

/*========================================
	Top Sider Css Start
========================================*/
.db_home_slider {
  position: relative;
  background: url("https://i.ibb.co/fYdZV7j/top-sider-bg.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 700px;
  position: relative;
  z-index: 3;
  padding: 65px 0px;
}
.tg-themepostimg figcaption {
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
.tg-bannercontent {
  max-width: 750px;
  width: 100%;
  margin: 0px auto;
  padding: 30px 0 0;
  text-align: center;
  position: relative;
  // background: url("texture.png") no-repeat;
  background-position: top center;
}
.db_banner_contant {
  margin: 0 auto;
  display: table;
  background: #252a2e;
  box-shadow: -1rem 0 3rem rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 50px;
  padding-top: 0px;
  margin-top: 150px;
}
.db_face_images {
  width: 320px;
  height: 320px;
  border-radius: 100%;
  -box-shadow: -1rem 0 3rem rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  margin-top: -150px;
  position: relative;
  z-index: 999;
}
.circle-ripple {
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: -1;
  transform: translateY(-50%);
}
.circle-ripple {
  background-color: transparent;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  animation: ripple 0.7s linear infinite;
}
@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(28, 182, 152, 0.2),
      0 0 0 1em rgba(28, 182, 152, 0.2), 0 0 0 3em rgba(28, 182, 152, 0.2),
      0 0 0 5em rgba(28, 182, 152, 0.2);
  }
  100% {
    box-shadow: 0 0 0 1em rgba(28, 182, 152, 0.2),
      0 0 0 3em rgba(28, 182, 152, 0.2), 0 0 0 5em rgba(28, 182, 152, 0.2),
      0 0 0 8em rgba(28, 182, 152, 0);
  }
}
.db_face_images img {
  border: 12px solid #1cb698;
  border-radius: 100%;
}
.db_banner_text {
  margin-bottom: 30px;
 h1 {
  font-family: "Righteous", cursive;
  color: #fff;
  font-size: 48px;
  line-height: 50px;
  text-transform: uppercase;
}h2 {
  color: #fff;
  font-size: 17px;
  line-height: 24px;
}
}
.social {
  margin: 0 auto;
  display: table;
}
.social li {
  display: inline-block;
  margin: 0 5px;
  align-items: center;
 }
 .social li a {
  font-size: 20px;
  color: #fff;
  width: 42px;
  height: 42px;
  line-height: 42px;
  padding: 10px 10px;
  display: flex;
  border-radius: 100%;
  border: 1px solid #fff;
  } li a:hover {
  background: #1cb698;
  border-color: #1cb698;
}
 

/*========================================
	Top Sider Css END
========================================*/

/*========================================
	About us Css START
========================================*/
.db_section_title {
  margin: 0 auto;
  display: table;
}
.db_section_img {
  width: 60px;
  height: auto;
  padding: 10px;
  margin: 0 auto 10px;
  border-radius: 5px;
  background: #252a2e;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.db_section_title h2 {
  font-family: "Righteous", cursive;
  color: #fff;
  font-size: 48px;
  line-height: 50px;
  padding-bottom: 20px;
  position: relative;
  margin-bottom: 30px;
}
.db_section_title h2:after {
  position: absolute;
  content: "";
  border-bottom: 1px solid #1cb698;
  width: 40px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  display: table;
}
.db_section_title h2:before {
  position: absolute;
  content: "";
  border-bottom: 1px solid #1cb698;
  width: 80px;
  bottom: 8px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  display: table;
}

.db_section_title h3 {
  font-family: "Righteous", cursive;
  color: #fff;
  font-size: 28px;
  line-height: 50px;
  padding-bottom: 20px;
  position: relative;
  margin-bottom: 30px;
}
.db_section_title h3:after {
  position: absolute;
  content: "";
  border-bottom: 1px solid #1cb698;
  width: 40px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  display: table;
}
.db_section_title h3:before {
  position: absolute;
  content: "";
  border-bottom: 1px solid #1cb698;
  width: 80px;
  bottom: 8px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  display: table;
}
.db_common_text,
.db_common_text p,
.db_common_text a {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.db_common_text strong {
  font-weight: 600;
  color: #1cb698;
}
.db_about_text_wapper {
  margin-bottom: 30px;
}
.db_small_title {
  font-family: "Righteous", cursive;
  color: #fff;
  font-size: 28px;
  line-height: 30px;
  position: relative;
  letter-spacing: 0.5px;
  margin-bottom: 25px;
}
.db_personal_details strong {
  width: 120px;
  display: inline-block;
}

.db_personal_center {
  margin-left: 50px;
  display: flex;
  justify-content: center;
}
.db_personal_details li {
  line-height: 33px;
  display: flex;

}
.db_personal_details li span {
  // background: #1cb698;
  background-color: rgba(194, 69, 69, 0.993);
  // width: 100px;
  padding: 2px 8px;
  border-radius: 5px;
  font-weight: 600;
}
.db_my_interests {
  margin-left: -10px;
}
.db_my_interests li {
  display: inline-block;
  text-align: center;
  width: 100px;
  cursor: pointer;
  padding: 12px 10px;
  transition: all 0.8s ease 0s;
  margin: 0 10px;
  margin-bottom: 25px;
  border-radius: 10px;
  background: #252a2e;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12) inset;
}
.db_my_interests li:hover {
  background: #1cb698;
}
.db_my_interests li .fa {
  font-size: 30px;
}
.db_my_interests li span {
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 8px;
  display: block;
}
.my_interest_icon {
  color: #fff;
  font-size: 30px;
  margin: 0 auto;
}
.db_download_cv_button {
  margin: 30px auto 0;
  display: table;
}
/*========================================
	About us Css END
========================================*/

/*========================================
	Skills Css START
========================================*/
.candidatos {
  position: relative;
  margin-bottom: 20px;
}
.candidatos .parcial {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.candidatos .parcial .info {
  position: relative;
}
.candidatos .parcial .info .nome {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 15px;
  font-weight: 600;
  opacity: 0.9;
}
.candidatos .parcial .info .eleito,
.candidatos .parcial .info .segundo-turno {
  padding: 0 5px 2px 5px;
  border-radius: 2px;
}
.candidatos .parcial .progressBar .percentagem-num {
  position: absolute;
  top: -17px;
  right: -15px;
  font-weight: normal;
  width: 40px;
  height: 40px;
  background: #1cb698;
  text-align: center;
  line-height: 40px;
  border-radius: 50px;
  font-weight: 600;
  letter-spacing: 0px;
  font-size: 15px;
}
.candidatos .parcial .progressBar {
  position: relative;
  width: 100%;
  height: 7px;
  margin: 30px 0 2px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
}
.candidatos .parcial .percentagem {
  position: absolute;
  top: 0;
  left: 0;
  height: 7px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #1cb698;
  -webkit-transition: 3s all;
  -webkit-animation-duration: 3s;
  -webkit-animation-name: animationProgress;
}
.candidatos .parcial .partidas {
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}
.percentual {
  animation-name: animationProgress;
}
@-webkit-keyframes animationProgress {
  from {
    width: 0;
  }
}
@keyframes animationProgress {
  from {
    width: 0;
  }
}
/*========================================
	Skills Css END
========================================*/

/*========================================
	My Resume Css START
========================================*/
.db_education_item {
  margin-bottom: 30px;
  position: relative;
  display: block;
  border-right: 2px solid #1cb698;
}
.dark_box_bg {
  padding: 25px;
  background: #252a2e;
  box-shadow: 0px 18px 18px 0 rgba(0, 0, 0, 0.2);
}
.dark_box_bg h4,
.dark_box_bg h4 a {
  font-size: 22px;
  letter-spacing: 0px;
  line-height: 24px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 10px;
}
.dark_box_bg h4 a {
  color: #1cb698;
  font-style: italic;
}
.db_year {
  color: #1cb698;
  margin-bottom: 10px;
  font-weight: 600;
}
.db_education_details {
  padding-right: 35px;
  border-right: 1px solid #333;
}
.db_education_item::after {
  content: "";
  display: inline-block;
  position: absolute;
  background-color: #1cb698;
  border-radius: 10px;
  width: 36px;
  height: 2px;
  top: 50%;
  right: -37px;
  z-index: 1;
}
.db_education_item::before {
  content: "";
  display: inline-block;
  position: absolute;
  background-color: #1cb698;
  border-radius: 10px;
  width: 12px;
  height: 12px;
  top: 50%;
  right: -43px;
  border: 2px solid #1cb698;
  z-index: 2;
  transform: translateY(-45%);
}
.db_experience_item {
  margin-bottom: 30px;
  position: relative;
  display: block;
  border-left: 2px solid #1cb698;
}
.db_experience_details {
  padding-left: 35px;
  border-left: 1px solid #333;
}
.db_experience_item::after {
  content: "";
  display: inline-block;
  position: absolute;
  background-color: #1cb698;
  border-radius: 10px;
  width: 36px;
  height: 2px;
  top: 50%;
  left: -37px;
  z-index: 1;
}
.db_experience_item::before {
  content: "";
  display: inline-block;
  position: absolute;
  background-color: #1cb698;
  border-radius: 10px;
  width: 12px;
  height: 12px;
  top: 50%;
  left: -43px;
  border: 2px solid #1cb698;
  z-index: 2;
  transform: translateY(-45%);
}
.db_experience_item ul li {
  list-style: disc inside;
}
/*========================================
	My Resume Css END
========================================*/

/*========================================
	Recent Protfolio Css START
========================================*/
.link_more {
  color: #d2d2d2;
  text-decoration: none;
}

.db_portfolio_menu {
  display: table;
  margin: 0 auto 30px;
  display: table;
}
.db_portfolio_menu .btn01 {
  margin: 0px 5px;
  cursor: pointer;
  border: 0px !important;
  outline: 0px !important;
}
.db_portfolio_menu .btn01.active {
  background: #1cb698;
  border-color: #1cb698;
}
.Portfolio {
  animation: winanim 0.5s;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
@keyframes winanim {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.Portfolio figcaption {
  position: absolute;
  padding: 15px;
  height: 100%;
  width: 100%;
  top: auto;
  left: 0;
  color: #fff;
  bottom: 0;
  z-index: 10;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.Portfolio figcaption:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  -ms-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(0.95);
  -o-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg)
    scale(0.95);
  -ms-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg)
    scale(0.95);
  -moz-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg)
    scale(0.95);
  -webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg)
    scale(0.95);
  z-index: -1;
  opacity: 0;
}
.Portfolio figcaption .fa {
  margin: 0;
  padding: 0;
  font-size: 26px;
  font-weight: 400;
  line-height: 35px;
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  top: 45%;
  margin-top: -22px;
  transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
  -o-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -ms-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -moz-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -webkit-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0;
  letter-spacing: 0px;
}
.Portfolio figcaption .title {
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  right: 0;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
  top: 50%;
  margin-top: 30px;
  margin-top: -2px;
  transform: translateX(80px) translateY(0) translateZ(0) rotate(0deg) scale(1);
  -o-transform: translateX(80px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -ms-transform: translateX(80px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -moz-transform: translateX(80px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -webkit-transform: translateX(80px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  -ms-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0;
  letter-spacing: 0px;
}
.Portfolio figcaption .sub-title {
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  font-size: 14px;
  top: 50%;
  margin-top: 30px;
  transform: translateX(-70px) translateY(0) translateZ(0) rotate(0deg) scale(1);
  -o-transform: translateX(-70px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -ms-transform: translateX(-70px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -moz-transform: translateX(-70px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -webkit-transform: translateX(-70px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0;
  letter-spacing: 0px;
}
.Portfolio figcaption a {
  position: absolute;
  z-index: 1111;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-indent: -99999px;
}
.Portfolio:hover {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  -ms-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.Portfolio:hover figcaption {
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(28, 182, 152, 0.9);
}
.Portfolio:hover figcaption:after {
  opacity: 1;
  transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
  -o-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
  -ms-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
  -moz-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  -ms-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.Portfolio:hover figcaption .fa {
  transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
  -o-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -ms-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -moz-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -webkit-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  opacity: 1;
}
.Portfolio:hover figcaption .title {
  transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
  -o-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -ms-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -moz-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -webkit-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  opacity: 1;
}
.Portfolio:hover figcaption .sub-title {
  transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg) scale(1);
  -o-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -ms-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -moz-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -webkit-transform: translateX(0px) translateY(0) translateZ(0) rotate(0deg)
    scale(1);
  -moz-transition: 0.6s;
  -o-transition: 0.6s;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  opacity: 1;
}
/*========================================
	Recent Protfolio Css END
========================================*/

/*========================================
	Contact Block Css START
========================================*/
.contact_section_wapper {
  background-image: url("https://i.ibb.co/zsDWZ10/contact-bg.png");
  background-repeat: no-repeat;
  background-position: center center;
}
.contant_form_row {
  margin-bottom: 20px;
}
.contant_form .input-group {
  background-color: #252a2e;
  border: 0px solid #d2d2d2;
  border-radius: 5px;
  height: 50px;
  padding: 18px;
  line-height: 50px;
  box-shadow: 0px 18px 18px 0 rgba(0, 0, 0, 0.2);
  font-size: 15px;
  color: #fff;
  letter-spacing: 0.5px;
}
.contant_form .input-group::placeholder,
.contant_form .textarea-group::placeholder {
  color: #fff;
  opacity: 1;
}
.contant_form .textarea-group {
  background-color: #252a2e;
  border: 0px solid #d2d2d2;
  border-radius: 5px;
  height: 150px;
  padding: 18px;
  line-height: 20px;
  box-shadow: 0px 18px 18px 0 rgba(0, 0, 0, 0.2);
  font-size: 15px;
  width: 100%;
  color: #fff;
  letter-spacing: 0.5px;
}
.contant_form .btn01 {
  cursor: pointer;
  box-shadow: none;
}
.google_map_wapper {
  position: relative;
}
.google_map_box {
  border: 4px solid #252a2e;
  border-radius: 0px;
  display: block;
  box-shadow: 0px 18px 18px 0 rgba(0, 0, 0, 0.2),
    0px 18px 18px 0 rgba(0, 0, 0, 0.2) inset;
}
.google_map_box iframe {
  display: block;
  height: 500px !important;
}
.google_info_box {
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  width: 100%;
  display: table;
  margin: 0 auto;
}
.google_info {
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #252a2e;
  margin: 0 auto;
  display: table;
}
.google_info li {
  padding: 25px 30px 25px 65px;
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.2px;
  line-height: 25px;
  font-weight: 500;
}
.google_info li.address_icon {
  background-image: url("https://i.ibb.co/b5Pc3kp/address-icon.png");
  background-repeat: no-repeat;
  background-position: 24px center;
}
.google_info li.call_icon {
  background-image: url("https://i.ibb.co/VMt85kt/call-icon.png");
  background-repeat: no-repeat;
  border-top: 1px solid #1e2326;
  border-bottom: 1px solid #1e2326;
  background-position: 24px center;
}
.google_info li.email_icon {
  background-image: url("https://i.ibb.co/Y0K9dP9/email-icon.png");
  background-repeat: no-repeat;
  background-position: 24px center;
}
/*========================================
	Contact Block Css END
========================================*/

/*========================================
	Footer Block Css START
========================================*/
.footer_wapper {
  background-color: #252a2e;
  padding: 30px 0px;
  position: relative;
  text-align: center;
  padding-top: 50px;
}
.footer_wapper .social {
  margin-bottom: 10px;
}
/*========================================
	Footer Block Css END
========================================*/

/*========================================
	Responsive Css START
========================================*/

#cssmenu #head-mobile {
  display: block;
}
@media screen and (max-width: 1199px) {
  .db_my_interests li {
    margin: 0 5px;
    margin-bottom: 15px;
  }
  .dark_box_bg h4 {
    font-size: 18px;
    line-height: 20px;
  }
}
@media screen and (max-width: 991px) {
  .contact_info_section {
    margin-bottom: 40px;
  }
  .google_info li {
    padding: 25px 10px 25px 60px;
    font-size: 12px;
    line-height: 20px;
  }
  .db_personal_details {
    margin-bottom: 40px;
  }
  .db_my_interests li {
    width: 20%;
  }
  .portfolio
  #cssmenu > ul > li > a {
    margin: 0px;
  }
  .portfolio
  #cssmenu > ul > li > a:before {
    content: none;
  }
  .portfolio
  #cssmenu > ul > li > a:hover {
    color: #1cb698;
  }
  .right_header {
    position: relative;
    margin: 0px;
  }
  
#cssmenu #head-mobile {
  display: none;
}
}
@media screen and (max-width: 767px) {
  .contant_form {
    margin-bottom: 30px;
  }
  #cssmenu {
    top: 0px;
  }
  .db_each-skills {
    margin-bottom: 40px;
  }
  .db_portfolio_menu .btn01 {
    padding: 7px 8px;
  }
  .right_header {
    position: relative;
    margin-top: -20px;
  }
}
@media screen and (max-width: 575px) {
  section .container {
    padding: 0 20px;
  }
  .db_my_interests li {
    width: 30%;
  }
  .db_portfolio_section .nav {
    margin: 0 auto;
    display: table;
    text-align: center;
  }
  .db_portfolio_section .nav li {
    display: inline-block;
    margin-bottom: 10px;
  }
  .right_header {
    position: relative;
    margin-right: 20px;
  }
  .logo a {
    margin-left: 20px
  }
  .db_face_images
  .circle-ripple {
    width: 150px;
    height: 150px;
  }
}
@media screen and (max-width: 540px) {
}
@media screen and (max-width: 500px) {
}
@media screen and (max-width: 440px) {
  .google_map_box iframe {
    display: block;
    height: 540px;
  }
  .google_info {
    top: 25%;
  }
  .db_face_images {
    width: 220px;
    margin: -110px auto 30px !important;
    height: 220px;
  }
  .db_banner_contant {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px !important;
  }
  .circle-ripple {
    width: 100px;
    height: 100px;
  }
  .db_banner_text h1 {
    font-size: 32px;
    line-height: 42px;
  }
  .db_home_slider {
    min-height: 570px;
  }
  .db_banner_text h2 {
    font-size: 15px;
  }
  .db_banner_text {
    margin-bottom: 15px;
  }
  .social li a {
    font-size: 16px;
    width: 36px;
    height: 36px;
    line-height: 36px;
  }
  .social li {
    margin: 0 2px;
  }
  .db_section_title h2 {
    font-size: 34px;
    line-height: 40px;
  }
  .db_personal_details strong {
    width: 100px;
  }
  .db_small_title {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 15px;
  }
  .db_my_interests li {
    width: 45%;
  }
}
@media screen and (max-width: 380px) {
}
@media screen and (max-width: 360px) {
}

/*========================================
	Nav Css START
========================================*/

#cssmenu,
#cssmenu ul,
#cssmenu ul li,
#cssmenu ul li a,
#cssmenu #head-mobile {
  border: 0;
  list-style: none;
  line-height: 1;
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#cssmenu:after,
#cssmenu > ul:after {
  _content: ".";
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
#cssmenu {
  display: table;
  float: right;
  width: auto;
}
#cssmenu > ul {
  margin: 0px 0 0 0;
}
#cssmenu > ul > li {
  float: left;
  margin: 0px 0px;
  padding: 0 0px;
  border-right: 0px solid #fff;
}
#cssmenu > ul > li:last-child {
  border-right: 0px solid #fff;
}
#cssmenu > ul > li > a {
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.5px;
  text-align: center;
  transition: all 0.8s ease 0s;
  text-decoration: none;
  padding: 13px 17px;
  display: block;
  color: #fff;
}
#cssmenu > ul > li:hover > a,
#cssmenu ul li.active a {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
#cssmenu > ul > li:hover,
#cssmenu ul li.active:hover,
#cssmenu ul li.active,
#cssmenu ul li.has-sub.active:hover {
  _background: #4f1387 !important;
  -webkit-transition: _background 0.3s ease;
  -ms-transition: _background 0.3s ease;
  transition: _background 0.3s ease;
}
#cssmenu > ul > li.has-sub > a {
}
#cssmenu > ul > li.has-sub > a:after {
  position: absolute;
  top: 22px;
  right: 11px;
  width: 8px;
  height: 2px;
  display: block;
  background: none;
  content: "";
}
#cssmenu > ul > li.has-sub > a:before {
  position: absolute;
  top: 19px;
  right: 14px;
  display: block;
  width: 2px;
  height: 8px;
  background: none;
  content: "";
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
#cssmenu > ul > li.has-sub:hover > a:before {
  top: 23px;
  height: 0;
}
#cssmenu ul ul {
  position: absolute;
  left: -9999px;
  z-index: 2;
  padding-top: 17px;
  top: 49px;
}
#cssmenu ul ul li {
  height: 0;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background-color: rgba(3, 11, 25, 0.7);
  border-bottom: 1px solid #979797;
}
#cssmenu ul ul li:hover {
  background: #002563;
}
#cssmenu li:hover > ul {
  left: auto;
}
#cssmenu li:hover > ul > li {
  height: 35px;
}
#cssmenu ul ul ul {
  margin-left: 100%;
  top: 0;
}
#cssmenu ul ul li a {
  _border-bottom: 1px solid rgba(150, 150, 150, 0.15);
  padding: 11px 15px;
  width: 210px;
  font-size: 15px;
  text-decoration: none;
  color: #fff;
  font-weight: 400;
  // background: url(../images/has-sub.png) no-repeat 6px 17px;
}
#cssmenu ul ul li:last-child > a,
#cssmenu ul ul li.last-item > a {
}
#cssmenu ul ul li:hover > a,
#cssmenu ul ul li a:hover {
  color: #fff;
  // background: url(../images/has-sub.png) no-repeat 6px 17px;
  padding-left: 20px;
  border-bottom: 0px solid rgba(150, 150, 150, 0.15);
}
#cssmenu ul ul li.has-sub > a:after {
  position: absolute;
  top: 16px;
  right: 11px;
  width: 8px;
  height: 2px;
  display: block;
  background: #ddd;
  content: "";
}
#cssmenu ul ul li.has-sub > a:before {
  position: absolute;
  top: 13px;
  right: 14px;
  display: block;
  width: 2px;
  height: 8px;
  background: #ddd;
  content: "";
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
#cssmenu ul ul > li.has-sub:hover > a:before {
  top: 17px;
  height: 0;
}
#cssmenu ul ul li.has-sub:hover,
#cssmenu ul li.has-sub ul li.has-sub ul li:hover {
  background: #002563;
}
#cssmenu ul ul ul li.active a {
  border-left: 1px solid #333;
}
#cssmenu > ul > li.has-sub > ul > li.active > a,
#cssmenu > ul ul > li.has-sub > ul > li.active > a {
  border-top: 1px solid #333;
}
@media screen and (max-width: 991px) {
  #cssmenu {
    display: block !important;
  }
  #cssmenu ul ul {
    padding-top: 0;
    z-index: 2;
    top: 0px;
  }
  .sub-menu.open {
    _border-top: 1px solid #979797 !important;
  }
  .sticky #cssmenu > ul > li {
    border-right: 0px solid #333333;
  }
  #cssmenu ul li.has-sub:hover ul {
    top: 0px;
  }
  nav {
    width: 100%;
  }
  #cssmenu {
    margin: 0px auto 0;
    width: 100%;
  }
  #cssmenu ul {
    border-radius: 6px;
    display: none;
    float: right;
    overflow: hidden;
    width: 180px;
    margin-top: 5px;
    position: relative;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding-top: 0px;
  }
  #cssmenu ul::after {
    // background-image: url("../images/mobile_top_arrow.png");
    background-position: right top;
    background-repeat: no-repeat;
    content: "";
    height: 12px;
    position: absolute;
    right: 16px;
    top: 0;
    visibility: unset;
    width: 27px;
  }
  #cssmenu ul li {
    width: 100%;
    border-top: 1px solid #e2e2e2;
    background: #022765;
    padding: 0px;
  }
  #cssmenu ul li:hover {
    _background: #030b19;
    _border-bottom: 1px solid #979797;
  }
  #cssmenu ul ul li,
  #cssmenu li:hover > ul > li {
    height: auto;
  }
  #cssmenu > ul > li:first-child > a {
    border-radius: 6px 6px 0 0;
  }
  #cssmenu ul li a,
  #cssmenu ul ul li a {
    width: 100%;
    border-bottom: 0;
    color: #fff;
  }
  .sticky #cssmenu > ul > li > a,
  #cssmenu > ul > li > a {
    color: #fff;
  }
  #cssmenu > ul > li {
    float: none;
  }
  #cssmenu ul ul li a {
    padding-left: 20px;
  }
  #cssmenu ul ul li a:hover {
    padding-left: 25px;
  }
  #cssmenu ul ul li {
    background-color: #db9e27;
    border-bottom: 0px solid #979797;
    _border-top: 1px solid #979797;
  }
  #cssmenu > ul > li:last-child {
    margin: 0 0 0 0px;
  }
  #cssmenu ul ul li:hover {
    _background: none;
  }
  #cssmenu ul ul ul li a {
    _padding-left: 35px;
  }
  #cssmenu ul ul li a {
    color: #fff;
  }
  #cssmenu > ul > li:first-child {
    border-top: 0 !important;
  }
  #cssmenu ul ul li:hover > a,
  #cssmenu ul ul li.active > a {
    color: #fff;
    _border-bottom: 1px solid #fff;
    // background: url("../images/has-sub.png") no-repeat 6px 17px;
  }
  #cssmenu > ul > li:hover,
  #cssmenu ul li.active:hover,
  #cssmenu ul li.active,
  #cssmenu ul li.has-sub.active:hover {
    border-bottom: 0px solid #fff;
  }
  #cssmenu ul ul,
  #cssmenu ul ul ul {
    position: relative;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: left;
  }
  #cssmenu > ul > li.has-sub > a:after,
  #cssmenu > ul > li.has-sub > a:before,
  #cssmenu ul ul > li.has-sub > a:after,
  #cssmenu ul ul > li.has-sub > a:before {
    display: none;
  }
  #cssmenu #head-mobile {
    color: #fff;
    display: none;
    font-size: 15px;
    font-weight: normal;
    padding: 16px 0 5px;
  }
  #cssmenu {
    display: table;
    /* float: left; */
    margin: 0 auto;
    position: absolute;
    top: 20px;
    /* right: 200px; */
    padding: 0 0px;
    z-index: 9999;
    right: 0;
  }
  #cssmenu .button {
    background: #1cb698 none repeat scroll 0 0;
    cursor: pointer;
    height: 40px;
    position: absolute;
    right: 0px;
    top: -40px;
    transition: all 0.5s ease 0s;
    border-radius: 5px;
    width: 45px;
    z-index: 12;
  }
  #cssmenu .button:hover {
    transition: all 0.5s ease 0s;
  }
  #cssmenu .button:after {
    position: absolute;
    top: 19px;
    right: 0;
    left: 0px;
    margin: 0 auto;
    display: table;
    height: 8px;
    width: 20px;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    content: "";
  }
  #cssmenu .button:before {
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: absolute;
    top: 13px;
    right: 0;
    left: 0px;
    display: table;
    height: 2px;
    margin: 0 auto;
    width: 20px;
    background: #fff;
    content: "";
  }
  #cssmenu .button.menu-opened:after {
    position: absolute;
    top: 22px;
    right: 0px;
    left: 0px;
    display: table;
    margin: 0 auto;
    height: 8px;
    width: 20px;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    content: "";
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    top: 19px;
    border: 0;
    height: 2px;
    width: 19px;
    background: #fff;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  #cssmenu .button.menu-opened:before {
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: absolute;
    top: 19px;
    right: 0px;
    left: 0px;
    margin: 0 auto;
    display: table;
    height: 2px;
    width: 20px;
    background: #fff;
    content: "";
    top: 19px;
    background: #fff;
    width: 19px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  #cssmenu .submenu-button {
    position: absolute;
    z-index: 99;
    right: 0;
    top: 0;
    display: block;
    border-left: 1px solid #979797;
    height: 45px;
    width: 50px;
    cursor: pointer;
  }
  #cssmenu .submenu-button.submenu-opened {
    _background: rgba(3, 11, 25, 0.7);
  }
  #cssmenu ul ul .submenu-button {
    height: 37px;
    width: 34px;
  }
  #cssmenu .submenu-button:after {
    position: absolute;
    top: 22px;
    right: 19px;
    width: 8px;
    height: 2px;
    display: block;
    background: #ddd;
    content: "";
  }
  #cssmenu ul ul .submenu-button:after {
    top: 15px;
    right: 13px;
  }
  #cssmenu .submenu-button.submenu-opened:after {
    background: #fff;
  }
  #cssmenu .submenu-button:before {
    position: absolute;
    top: 19px;
    right: 22px;
    display: block;
    width: 2px;
    height: 8px;
    background: #ddd;
    content: "";
  }
  #cssmenu ul ul .submenu-button:before {
    top: 12px;
    right: 16px;
  }
  #cssmenu .submenu-button.submenu-opened:before {
    display: none;
  }
  #cssmenu ul ul ul li.active a {
    border-left: none;
  }
  #cssmenu > ul > li.has-sub > ul > li.active > a,
  #cssmenu > ul ul > li.has-sub > ul > li.active > a {
    _border-bottom: 1px solid #fff;
  }
  #cssmenu ul ul li:hover > a,
  #cssmenu ul ul li a:hover {
    color: #fff;
    padding-left: 25px !important;
    border-bottom: 0px solid #fff;
    // background: #95b721 url("../images/has-sub.png") no-repeat 6px 17px;
  }
  #cssmenu > ul > li > a {
    background: #252a2e;
    padding: 10px 15px !important;
    color: #fff;
    text-align: left;
    border-radius: 0px;
  }
  #cssmenu ul li {
    background: none;
  }
}
#cssmenu .sub-menu li ul li a {
  font-size: 13px !important;
  padding-right: 0;
}
#cssmenu li ul.sub-menu > li > ul.sub-menu {
  padding-top: 0;
}
/* Underline From Center */
.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: #002c56;
  height: 3px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-from-center:hover:before,
.hvr-underline-from-center:focus:before,
.hvr-underline-from-center:active:before {
  left: 0;
  right: 0;
}
}


/*========================================
	Animation css START
========================================*/
@keyframes fadeInUpHeader {
  0% {
    margin-top: 0;
    transform: translate3d(0, 60%, 0);
  }

  25% {
    margin-top: 10px;
  }

  50% {
    margin-top: 0;
  }

  75% {
    margin-top: 12px;
  }

  100% {
    margin-top: 0;
    transform: translate3d(0, 0, 0);
  }
}

#header {
  animation-name: fadeInUpHeader;
  animation-duration: 5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 2s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 2s;
}

/*========================================
	Animation css End
========================================*/