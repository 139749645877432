body {
    background-color: #252a2e;

    h1 {
        margin-top: 10%;
        color: beige;
    }

    .db_section_sub_title {
        font-size: 25px;
        font-weight: 600;
    }
    .card_description {
        font-size: 22px;
    }
}

#post {
    margin-top: 40px;
}