
:root {
  --bg-color: #f5f5f5;
  --bg-color2: #040404;
  --font-color: #222;
  --font-color-2: #57269f;
  --font-color-3: #c0c0c0;
  --font-color-4: #fff;
}

body {
    max-height: 100vh;
    overflow-x: hidden;
    background-color: #040404;
  }

.lightBio, .bio {
  overflow: hidden;
  background-color: var(--bg-color);
  width: 100%;height: 100vh;
  color: #fff;

 

  ::-webkit-scrollbar {
      width: 0;
  }

  .button-swift {
      display: table-cell;
      position: relative;
      width: 200px;
      height: 140px;
      float: right;
      box-sizing: border-box;
      
      .button-cover
  {
      height: 100px;
      margin: 20px;
      border-radius: 4px;
  
  &, .knobs, .layer
  {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      
      }
  
      .knobs
  {
      z-index: 2;
  
      span {
          display: flex;
          margin-left: 10px; 
          margin-top: 10px;
          z-index: 5;
          color: #fff
        }
  }
  
  .layer
  {
      width: 100%;
      background-color: var(--bg-color2);
      transition: 0.3s ease all;
      z-index: 1;
  
      span {
          display: flex;
          margin-left: 40px; 
          margin-top: 5px;
          font-size: 23px;
          color: #fff
        }
  }
  
  .button
  {
      position: relative;
      top: 50px;
      width: 74px;
      height: 36px;
      margin: -20px auto 0 auto;
      overflow: hidden;
      @media screen and (max-width: 620px){ 
          margin-right: 6px;
      }
  
  &.r, &.r .layer
  {
      border-radius: 100px;
  }
  }
  
  .checkbox
  {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      opacity: 0;
      cursor: pointer;
      z-index: 3;
  }
  }
  
  /* Button 1 */
  #button-1 {
  
      & .knobs:before
      {
        content: '';
          position: absolute;
          top: 4px;
          left: 4px;
          width: 20px;
          height: 10px;
          color: #fff;
          font-size: 10px;
          font-weight: bold;
          text-align: center;
          line-height: 1;
          padding: 9px 4px;
          background-color: #fff;
          border-radius: 50%;
          transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
          
      }
      
      & .checkbox:checked + .knobs:before
      {
          content: '';
          left: 42px;
          background-color: #fff;
      }
      
      & .checkbox:checked ~ .layer
      {
          background-color: var(--font-color-2);
      }
      
      & .knobs, & .knobs:before, & .layer
      {
          transition: 0.3s ease all;
      }
      }
  }

main {
// background-color: var(--font-color-2);
max-width: 600px;  
position: absolute;
max-height: 140%;
top: 50%;
left: 50%;
margin-right: -50%;
transform: translate(-50%, -50%);
overflow: auto;

@media screen and (max-width: 540px){ 
  margin-top: 0px; 
}
  .container {
      position: relative;
      max-height: 100%; 
      overflow: auto;
      text-align: center;
      max-width: 600px;
      margin-bottom: 150px;
      // background-color: #57269f;
      .profile-image img{
          margin-top: 200px;
          width: 150px;
          height: 150px;
          border-radius: 50%;
      }

      .username {
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          font-size: 22px;
          font-weight: 500;
          white-space: 2px;
          margin-bottom: 1px;
          color: var(--font-color);
      }
      .insta_user {
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          font-size: 17px;
          font-weight: 500;
          margin-bottom: 20px;
          color: var(--font-color-3);
          }
  }

.container_content {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  place-content: center;

  a {
      text-decoration: none;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
  }

  .media-social {
      background-color: #c0c0c0;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 250px;
      height: 100px;
      margin: 6px;
      border-radius: 10px;
      cursor: pointer;

      &:hover {
          border: 3px solid #222;
          margin: 4px;
      }

      .media-icon {
          font-size: 30px;
      }

      span {
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          font-size: 19px;
      }
     
  
  &:nth-child(1), .instagram{
      background: linear-gradient(
          45deg
          , #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  }
  &:nth-child(2), .linkedin {
      background: linear-gradient(
          90deg
          , rgba(111,139,231,1) 0%, rgba(47,41,157,1) 100%);
  }
  // &:nth-child(3), .discord{
  //     background: #202225;
  // }
  // &:nth-child(4), .desafio{    
  //     background: linear-gradient(
  //         276.83deg
  //     , #F2994A 0%, #da9c00 100%);
  //     }
  
  &:nth-child(3), .portfolio{    background: linear-gradient(
      58deg
      , #722CD8 8%, #9A56FD 98%);
  }
  &:nth-child(4), .apresentation{
    //   margin-bottom: 30px;
      background: linear-gradient(
          276.83deg
      , #F2994A 0%, #da9c00 100%);
  }
  }  
  }   
}
}

.darkBio, .bio {
  background-color: var(--bg-color2);
  width: 100%;
  min-height: 100vh;
  color: #fff;

  ::-webkit-scrollbar {
      width: 0;
  }

  .button-swift {
      display: table-cell;
      position: relative;
      width: 200px;
      height: 140px;
      float: right;
      box-sizing: border-box;
      
      .button-cover
  {
      height: 100px;
      margin: 20px;
      border-radius: 4px;
  
  &, .knobs, .layer
  {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      
      }
  
      .knobs
  {
      z-index: 2;
  
      span {
          display: flex;
          margin-left: 10px; 
          margin-top: 10px;
          z-index: 5;
          color: #fff
        }
  }
  
  .layer
  {
      width: 100%;
      background-color: var(--bg-color2);
      transition: 0.3s ease all;
      z-index: 1;
  
      span {
          display: flex;
          margin-left: 40px; 
          margin-top: 5px;
          font-size: 23px;
          color: #fff
        }
  }
  
  .button
  {
      position: relative;
      top: 50px;
      width: 74px;
      height: 36px;
      margin: -20px auto 0 auto;
      overflow: hidden;
      @media screen and (max-width: 620px){ 
          margin-right: 6px;
      }
  
  &.r, &.r .layer
  {
      border-radius: 100px;
  }
  }
  
  .checkbox
  {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      opacity: 0;
      cursor: pointer;
      z-index: 3;
  }
  }
  
  /* Button 1 */
  #button-1 {
  
      & .knobs:before
      {
        content: '';
          position: absolute;
          top: 4px;
          left: 4px;
          width: 20px;
          height: 10px;
          color: #fff;
          font-size: 10px;
          font-weight: bold;
          text-align: center;
          line-height: 1;
          padding: 9px 4px;
          background-color: #fff;
          border-radius: 50%;
          transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
          
      }
      
      & .checkbox:checked + .knobs:before
      {
          content: '';
          left: 42px;
          background-color: #fff;
      }
      
      & .checkbox:checked ~ .layer
      {
          background-color: var(--font-color-2);
      }
      
      & .knobs, & .knobs:before, & .layer
      {
          transition: 0.3s ease all;
      }
      }
  }

main {
// background-color: var(--font-color-2);
max-width: 600px;  
position: absolute;
max-height: 140%;
top: 50%;
left: 50%;
margin-right: -50%;
transform: translate(-50%, -50%);
overflow: auto;

@media screen and (max-width: 540px){ 
  margin-top: 0px; 
}
  .container {
      position: relative;
      max-height: 100%; 
      overflow: auto;
      text-align: center;
      max-width: 600px;
      margin-bottom: 150px;
      // background-color: #57269f;
      .profile-image img{
          margin-top: 200px;
          width: 150px;
          height: 150px;
          border-radius: 50%;
      }

      .username {
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          font-size: 22px;
          font-weight: 500;
          white-space: 2px;
          margin-bottom: 1px;
          color: var(--font-color-4);
      }
      .insta_user {
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          font-size: 17px;
          font-weight: 500;
          margin-bottom: 20px;
          color: var(--font-color-3);
          }
  }

.container_content {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  place-content: center;

  a {
      text-decoration: none;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
  }

  .media-social {
    //   background-color: #c0c0c0;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 250px;
      height: 100px;
      margin: 6px;
      border-radius: 10px;
      cursor: pointer;

      &:hover {
          border: 3px solid #fff;
          margin: 6px;
      }

      .media-icon {
          font-size: 30px;
      }

      span {
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          font-size: 19px;
      }
     
  
  &:nth-child(1), .instagram{
      background: linear-gradient(
          45deg
          , #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  }
  &:nth-child(2), .linkedin {
      background: linear-gradient(
          90deg
          , rgba(111,139,231,1) 0%, rgba(47,41,157,1) 100%);
  }
  
  
  &:nth-child(3), .portfolio{    background: linear-gradient(
      58deg
      , #722CD8 8%, #9A56FD 98%);
  }
  &:nth-child(4), .github{
      background: linear-gradient(
          58deg
          , #1f9b95 8%, #3983b4 98%);
  }

  &:nth-child(5), .apresentation{
      width: 512px;
      margin-bottom: 30px;
      background: linear-gradient(
          276.83deg
      , #F2994A 0%, #da9c00 100%);
  }

  @media screen and (max-width: 560px) {
    &:nth-child(5), .apresentation{
       width: 250px;
   }
   }

  // &:nth-child(), .discord{
  //     background: #202225;
  // }
  // &:nth-child(), .desafio{    
  //     background: linear-gradient(
  //         276.83deg
  //     , #F2994A 0%, #da9c00 100%);
  //     }
  

  } 


  }  
  }   
}

